import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Store from './Store';
const root = ReactDOM.createRoot(document.getElementById('root'));

// For GET requests
axios.interceptors.request.use(
   (req) => {
      // Add configurations here
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);

// For POST requests
axios.interceptors.response.use(
   (res) => {
      // Add configurations here
      if (res.status === 201) {
         console.log('Posted Successfully');
      }
      return res;
   },
   (err) => {
      return Promise.reject(err);
   }
);
// Register the service worker
if ('serviceWorker' in navigator) {
   window.addEventListener('load', function() {
     navigator.serviceWorker.register('/.service-worker.js')
       .then(function(registration) {
         console.log('Service worker registered with scope:', registration.scope);
       }, function(err) {
         console.log('Service worker registration failed:', err);
       });
   });
 }
root.render(
  <Provider store={Store}>

  
  <Auth0Provider
    domain="dev-i4edhe4i422yc70q.us.auth0.com"
    clientId="aebsGVTtVD3w1XkiDkKcUa9U5b6EVKQR"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <App />
    
  </Auth0Provider>
  </Provider>
  
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
