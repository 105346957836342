import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import Welcome from "../inc/Welcome";
import Sidebar from "../inc/Sidebar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box, LinearProgress } from "@mui/material";
import AppbarTop from "../inc/Appbar_Top";
import Appbar_Bottom from "../inc/Appbar_Bottom";
import { useDispatch, useSelector } from "react-redux";
import { useCreateBuyerMutation, useGetBuyerByIdQuery, useGetSquadByIdQuery, useGetSquadDealsBySquadIdQuery } from "../Store/api/apiSlice";
import { setBuyer, updateBuyer } from "../Store/slices/buyersSlice";
import { setSquad } from "../Store/slices/squadsSlice"; // Ensure this path is correct
import { setSquadDeals } from "../Store/slices/dealSlice";

const Home = () => {
  const { isAuthenticated, isLoading: authLoading, user } = useAuth0();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const dispatch = useDispatch();

  // Check is user is authenticated
  useEffect(() => {
    if (isAuthenticated === true) {
      setIsDataLoading(false);
    }else if (isAuthenticated === false){
      setIsDataLoading(false)
    }
    // console.log(isAuthenticated);
  }, [isAuthenticated]);

 
  //State Management
  const selector = useSelector(state => state.buyer)
  
  // New user data variable
  const [newUserData,setNewUserData] = useState({})
      
  // Squad state management
    const [squadId, setSquadId] = useState(null);

  //User email id
   // Load buyer data
   const {
    data: buyerData,
    error: buyerError,
    isLoading: buyerLoading
  } = useGetBuyerByIdQuery(user?.email, {skip :!user?.email});

  // Mutation hook to create a new buyer
  const [createBuyer, { data: createdUserData, error: createError, isLoading: createLoading }] = useCreateBuyerMutation();

  // Load squad data only after squadId is set
  const {
    data: squadData,
    error: squadError,
    isLoading: squadLoading
  } = useGetSquadByIdQuery(squadId, { skip: !squadId });

  const {
    data: squadDealsData,
    error: squadDealsError,
    isLoading: squadDealsLoading
  } = useGetSquadDealsBySquadIdQuery(squadId, { skip: !squadId });

  useEffect(() => {
    if (createdUserData) {
      dispatch(setBuyer(createdUserData));
      setSquadId(createdUserData.squadId); // Set squadId from created buyer data
      console.log(createdUserData);
    }
  }, [createdUserData, dispatch]);

  useEffect(() => {
    if (!squadLoading && squadData) {
      dispatch(setSquad(squadData));
    }
  }, [squadData, squadLoading, dispatch]);
  
  useEffect(() => {
    if (!squadDealsLoading && squadDealsData) {
      dispatch(setSquadDeals(squadDealsData));
    }
  }, [squadDealsData, squadDealsLoading, dispatch]);


  //Push Data in redux
  useEffect(() => {
    if (!buyerLoading && buyerData) {
      dispatch(setBuyer(buyerData));
      setSquadId(buyerData.squadId); // Set squadId from buyer data
      // console.log(buyerData);
    } else if (!buyerLoading && buyerError?.status === 404) {
      // console.log(selector);
      dispatch(updateBuyer({name : user?.name, email :user?.email}))
      if(selector.email !== undefined){
            createBuyer(selector)
            console.log(selector);
          }
      console.log(selector);
    }
  }, [ buyerData,buyerLoading,selector]);

  // useEffect(()=>{
  //   if(selector.email !== undefined){
  //     createBuyer(selector)
  //     console.log(selector);
  //   }
  // },[selector])
  
  return (
    <div style={{ height: "100%" }}>
      {isDataLoading ? (
        <Box
          sx={{
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <img
            src="/logo.png"
            alt="Squad10"
            style={{ width: "100%", maxWidth: "400px" }}
          />
          <LinearProgress />
          <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
            Loading
          </Typography>
        </Box>
      ) : isAuthenticated ? (
        <>
          <AppbarTop />
          <Box sx={{ display: "flex" }}>
            <Appbar_Bottom />
            <Box component="main" sx={{ flexGrow: 1, p: 1, marginBottom: 15 }}>
              <Toolbar />
              <Outlet />
            </Box>
          </Box>
        </>
      ) : (
        <Welcome />
      )}
    </div>
  );
};

export default Home;
