import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AccountCircle, AutoFixHigh, BugReport, CurrencyRupee, Dashboard, Explore, GridView, Logout, PowerSettingsNew } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import  ClickAwayListener  from "@mui/material/ClickAwayListener";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  boxShadow : "rgb(136 165 191 / 37%) 2px 0px 9px 7px, rgb(255 255 255 / 80%) 0px 0px 0px 0px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow : "rgb(136 165 191 / 37%) 2px 0px 9px 7px, rgb(255 255 255 / 80%) 0px 0px 0px 0px",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // backgroundColor : "#efeff0",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [newRoute, setNewRoute] = React.useState('/');
  const { logout } = useAuth0();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const handleDrawerOutsideClick = () => {
    setOpen(false);
  };
  const handleClick = (newOneRoute) => {
    navigate(newOneRoute);
    setOpen(false);
  };
  useEffect(() => {
    navigate(newRoute);
  },[])
  return (
    <Box sx={{ display: "flex"}}>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleDrawerOutsideClick}>
      <Drawer variant="permanent" open={open}>
        <IconButton onClick={handleDrawerToggle} sx={{marginTop:10}}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List>
          {
          // {menu: "Dashboard",icon : <GridView />, iconClose: <GridView />,newRoute : 'dashboard'},
          [
            {menu: "My Squad",icon : <GridView />, iconClose: <GridView />,newRoute : '/'},
            {menu: "Explore",icon : <Explore />, iconClose:<Explore />,newRoute : 'explore' },
            {menu: "Profile",icon : <AccountCircle />, iconClose:<AccountCircle />,newRoute : 'profile'},
            {menu: "Report Bug",icon : <BugReport />, iconClose:<BugReport />,newRoute : 'bug-report'},
          ].map((menu, index) => (
            <ListItem
              key={menu.menu}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {handleClick(menu.newRoute)}}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {open ? menu.icon : menu.iconClose}
                </ListItemIcon>
                <ListItemText primary={menu.menu} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {open ? <PowerSettingsNew /> : <PowerSettingsNew />}
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography>Logout</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      </ClickAwayListener>
    </Box>
  );
}
