import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: "https://squad-10-fastapi.onrender.com" }),
    // baseQuery: fetchBaseQuery({ baseUrl: "http://127.0.0.1:8000" }),
    tagTypes: ['Properties', 'Squad', 'Buyer', 'SquadDeals'],
    endpoints: (builder) => ({
        getProperties: builder.query({
            query: () => '/properties',
            providesTags: ['Properties'],
        }),
        getPropertyById: builder.query({
            query: (id) => `/properties/${id}`,
            providesTags: (result, error, id) => [{ type: 'Properties', id }],
        }),
        getBuyerById: builder.query({
            query: (id) => `/buyer/${id}`,
            providesTags: (result, error, id) => [{ type: 'Buyer', id }],
        }),
        createBuyer: builder.mutation({
            query: (body) => ({
                url : '/buyer',
                method : 'POST',
                body
            }),
            invalidatesTags: ['Buyer'],
        }),
        getSquadById: builder.query({
            query: (id) => `/squad/${id}`,
            providesTags: (result, error, id) => [{ type: 'Squad', id }],
        }),
        getSquadDealsBySquadId: builder.query({
            query: (id) => `/deal/squad/${id}`,
            providesTags: (result, error, id) => [{ type: 'SquadDeals', id }],
        }),
    }),
});

export const {
    useGetPropertiesQuery,
    useGetPropertyByIdQuery,
    useGetBuyerByIdQuery,
    useCreateBuyerMutation,
    useGetSquadByIdQuery,
    useGetSquadDealsBySquadIdQuery
} = apiSlice;
