import { AccountCircle, Add, AssistantPhotoOutlined, BugReport, Explore, GridView, MenuBookTwoTone, More, Search } from '@mui/icons-material';
import { AppBar, Box, Button, Fab, IconButton, Menu, MenuItem, styled, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const Appbar_Bottom = () => {
  const [open, setOpen] = useState(false);
  const selector = useSelector(state => state.buyer)
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate()
  const handleNavigation = (route) => {
    navigate(route)
  }
  const menuData = 
  [
    {label: "Deals",icon : <Explore sx={{marginRight:1, color:"#f08a63"}} />, newRoute : 'deals' },
    {label: "My Squad",icon : <GridView sx={{marginRight:1, color:"#c873a1"}} />,newRoute : '/'},
    {label: "Profile",icon : <AccountCircle sx={{marginRight:1, color:"#9dccc3"}} />, newRoute : 'profile'},
    {label: "Suggest A Project",icon : <AssistantPhotoOutlined sx={{marginRight:1, color:"#9dccc3"}} />, newRoute : 'suggest-project'}
  ]
  return (
    <div>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: "#fff" }}>
        <Toolbar>
          <IconButton color="#9dccc6" aria-label="open drawer">
            {selector?.membership?.membershipType ? 
              <Typography>{selector.membership?.membershipType}</Typography> :
            <Typography>Free</Typography>
          }
          </IconButton>
          <StyledFab sx={{backgroundColor:"#9dccc6", color:"#fff"}} aria-label="add" onClick={handleClick}>
            <MenuBookTwoTone />
          </StyledFab>
          <Menu
            open={open}
            onClose={handleClose}
            anchorEl={document.querySelector('.MuiFab-root')}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {menuData.map((menu)=>{
            return <MenuItem onClick={()=>{handleNavigation(menu.newRoute)}}>{menu.icon}  {menu.label}</MenuItem>
            })}
          </Menu>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="secondary">
            <Typography>v1.4</Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Appbar_Bottom;
