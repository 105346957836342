import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import { Done } from "@mui/icons-material";


const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;


const validationSchema = yup.object({
  job_title: yup
    .string("Enter your job title")
    .required("Job title is required"),
  cont_per_full_name: yup
    .string("Enter conact person Full Name")
    .required("Full Name is required"),
  cont_per_mob_num: yup.string().matches(phoneRegExp, 'Phone number is not valid')
    .required('Contact number is required'),
  description: yup
    .string("Enter your description"),
  expected_experience: yup.number()
    .required("Expected Experience is required"),
  sal_range: yup.number()
    .required("Salary range is required"),
  app_link: yup.string().url()
    .typeError("Enter Application link")
    .required("Application link  is required")
});

const Create = () => {
  const [jobId, setJobId] = useState("");
  const [fullName, setFullName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobTags, setJobTags] = useState([]);
  const [jobApplicationLink, setJobApplicationLink] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [salary, setSalary] = useState("");
  const [experience, setExperience] = useState("");
  const [completeJobsFormData, setcompleteJobsFormData] = useState();
  const [ifJob, setifJob] = useState(true);
  const [randomId, setRandomId] = useState();
  const [ifJobsFormSubmitted, setIfJobsFormSubmitted] = useState(false);


  const { user } = useAuth0()

  useEffect(() => {
    setRandomId(Math.floor(Math.random() * 1000000))
  }, [])
  useEffect(() => {
    setcompleteJobsFormData({
      job_id: user.email + randomId,
      title: formik.values.job_title,
      tags: jobTags,
      job_application_link: formik.values.app_link,
      cont_per_full_name: formik.values.cont_per_full_name,
      cont_per_mob_num: formik.values.cont_per_mob_num,
      description: formik.values.description,
      expected_experience: formik.values.expected_experience,
      offered_salary: formik.values.sal_range,
      status: 'pending'
    });
  }, [
    jobId,
    jobTitle,
    jobApplicationLink,
    fullName,
    jobTags,
    mobile,
    description,
    salary,
    experience, completeJobsFormData
  ]);

  const formik = useFormik({
    initialValues: {
      job_title: "",
      cont_per_full_name: "",
      cont_per_mob_num: "",
      description: "",
      expected_experience: "",
      sal_range: "",
      app_link: ""
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('job');
      axios
        .post(
          "https://squad10-backend.onrender.com/jobs",
          completeJobsFormData
        )
        .then((data) => {
          console.log(data);
          setIfJobsFormSubmitted(true)
        });
    },
  });

  return (
    <div>
      <Box p={5} display="flex" justifyContent="center">
          <form
            style={{ width: "100%", textAlign: "center" }}
            onSubmit={formik.handleSubmit}
          >
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Job Title"
              id="job_title"
              variant="outlined"
              value={formik.values.job_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.job_title && formik.errors.job_title}
              helperText={formik.touched.job_title && formik.errors.job_title}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Contact Person Full Name"
              id="cont_per_full_name"
              variant="outlined"
              value={formik.values.cont_per_full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cont_per_full_name && formik.errors.cont_per_full_name}
              helperText={formik.touched.cont_per_full_name && formik.errors.cont_per_full_name}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Contact Person Mobile Number"
              id="cont_per_mob_num"
              variant="outlined"
              value={formik.values.cont_per_mob_num}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cont_per_mob_num && formik.errors.cont_per_mob_num}
              helperText={formik.touched.cont_per_mob_num && formik.errors.cont_per_mob_num}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Description"
              id="description"
              variant="outlined"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="number"
              label="Expected Experience in Years"
              id="expected_experience"
              variant="outlined"
              value={formik.values.expected_experience}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.expected_experience && formik.errors.expected_experience}
              helperText={formik.touched.expected_experience && formik.errors.expected_experience}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Minimum Monthly Salary"
              id="sal_range"
              variant="outlined"
              value={formik.values.sal_range}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sal_range && formik.errors.sal_range}
              helperText={formik.touched.sal_range && formik.errors.sal_range}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Application link"
              id="app_link"
              variant="outlined"
              value={formik.values.app_link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.app_link && formik.errors.app_link}
              helperText={formik.touched.app_link && formik.errors.app_link}
            />
            <br />
            <Box p={1}>

              {ifJobsFormSubmitted ?
                <>
                  <Typography variant="p">
                    Job Created Successfully! Once Approved from Admin It will be visible in Job Section of Community.
                  </Typography>
                  <Done style={{ padding: 2 }}> </Done>
                </>
                : <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>}
            </Box>
          </form>
      </Box>
    </div>
  );
};

export default Create;
