import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import "./welcome.css";

const Welcome = () => {
  const { loginWithRedirect } = useAuth0();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="welcome-container" style={{height:"100%"}}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        height={"100%"}
        px={2} // Add padding to avoid content touching the edges
      >
        <Grid
          item
          xs={12}
          md={6}
          p={2}
          className="customPadding"
          textAlign="center"
        >
          <img
            src="/logo.png"
            alt="Logo"
            style={{ width: "100%", maxWidth: "400px" }} // Adjust width for mobile
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          p={2}
        >
          <Box p={2}>
            <Box p={2} textAlign="center">
              <Button
                sx={{ margin: 1 }}
                variant="outlined"
                size="large"
                onClick={() => loginWithRedirect()}
              >
                Login/ Sign Up
              </Button>
            </Box>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ mb: 1 }} // Add margin bottom for spacing
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Agney
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  40 - 60 lacs
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Perfect for newly married couples looking for budget-friendly homes priced between 40 to 60 lakhs. Join for just Rs 99 and start your home-buying journey with exclusive deals and group discounts.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ mb: 1 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Nairutya
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  60 - 80 lacs
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Ideal for couples with a child seeking a vibrant community and homes priced between 60 to 80 lakhs. Subscribe for Rs 499 to access premium offers and collective savings, making your family home a reality.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{ mb: 1 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Vayavya
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  80 lacs - 1cr
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Designed for couples needing more space as their kids grow, with homes priced between 80 lakhs to 1 crore. Join the elite club for Rs 999 and enjoy unparalleled discounts and group benefits for a spacious future home.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={{ mb: 1 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Ishanya
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  1cr +
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Tailored for families needing ample space for 2 kids and grandparents, with luxurious homes priced over 1 crore. Subscribe for Rs 1999 to experience luxury living with exclusive offers and substantial group savings on premium real estate.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
      <FloatingWhatsApp
        style={{ height: "auto" }}
        phoneNumber="+917410773108"
        accountName="Sumit"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar="images/avatar/avatar.jpg"
        statusMessage="Typically replies within 20 mins"
      />
    </div>
  );
};

export default Welcome;
