import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FilterAlt from '@mui/icons-material/FilterAlt';
import { Box, Grid, List, useAutocomplete } from '@mui/material';
import { useSelector } from 'react-redux';

export default function JoinSquad() {
    
    const selector = useSelector(state => state)
    const [squadList, setSquadList] = React.useState([])
    React.useEffect(()=>{
        setSquadList([
            {name:"IT Squad",intrests:{
                areas:["Hinjewadi","Wakad","Balewadi"],
                tags : ["IT","2BHK","Pune","Family"]
            }, status:"active", reviews:3},
            {name:"Hinjewadi Squad",intrests:{
                areas:["Hinjewadi","Wakad","Balewadi"],
                tags : ["IT","2BHK","Pune","Family"]
            }, status:"active", reviews:3},

        ])
        console.log(selector);
    },[])
    const {
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: squadList,
        getOptionLabel: (option) => option?.name,
    });
    return (
        <Box sx={{ width: '100%' }}>
            <Paper
                component="form"
                sx={{ mt:2, display: 'flex', alignItems: 'center', width: '100%' }}
            >
                <Grid container sx={{ width: '100%' }}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Squad"
                            inputProps={{ ...getInputProps() }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <FilterAlt />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        {groupedOptions.length > 0 && (
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 300,
                                    '& ul': { padding: 0 },
                                }}
                                {...getListboxProps()}
                            >
                                {groupedOptions.map((option, index) => {
                                    const { key, ...optionProps } = getOptionProps({ option, index });
                                    return (
                                        <li key={key} {...optionProps}>
                                            {option?.name}
                                        </li>
                                    );
                                })}
                            </List>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
