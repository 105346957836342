import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Rating } from '@mui/material';
import { useSelector } from 'react-redux';

export default function SquadMembers() {
  const squadMembers = useSelector((state)=> state.squad.squad.members)
  // const squadMembersData = [
  //                             {memberId:"0",memberName:"Sumit",role:"cordinator",subscription:"Admin",ratings:2,shortBio:"This is Sumit from Swargate"},
  //                             {memberId:"1",memberName:"Priyanka",role:"member",subscription:"Agney",ratings:5,shortBio:"This is Sumit from Swargate"},
  //                             {memberId:"2",memberName:"Rahul",role:"member",subscription:"Nairutya",ratings:1,shortBio:"This is Rahul from Hinjewadi"},
  //                             {memberId:"2",memberName:"Jyoti",role:"member",subscription:"Agney",ratings:2,shortBio:"This is Rahul from Bavdhan"},
  //                          ]
  React.useEffect(()=>{
    console.log(squadMembers);
  },[])
                           return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {squadMembers.map((member)=>{
         return <>
         <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={member?.name} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={member?.name +" ( "+ member?.subscription+ " )"}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                  {member?.shortBio}
                  </Typography>
                  {member?.isAdmin == false && <Rating readOnly sx={{color:"#9dccc6"}} value={member?.ratings} />}
                </React.Fragment>
              }
            />
          </ListItem>
        <Divider variant="inset" component="li" />
         </>
        })}
      </List>
    </Box>
  );
}