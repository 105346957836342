import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "userId": undefined,
    "squadId": undefined,
    "username": undefined,
    "password": undefined,
    "email": undefined,
    "mobile": undefined,
    "name": undefined,
    "dob": undefined,
    "dobPretty": undefined,
    "gender": undefined,
    "maritalStatus": undefined,
    "profession": undefined,
    "annualIncome": undefined,
    "incomeRange": {
        "min": undefined,
        "max": undefined
    },
    "preferredPropertyType": undefined,
    "preferredLocation": undefined,
    "budget": {
        "min": undefined,
        "max": undefined
    },
    "currentStatus": undefined,
    "avatarImg": undefined,
    "address": {
        "addressLine": undefined,
        "city": undefined,
        "pincode": undefined,
        "stateCode": undefined,
        "state": undefined,
        "country": undefined
    },
    "familyDetails": {
        "motherName": undefined,
        "fatherName": undefined,
        "spouseName": undefined,
        "numberOfKids": undefined
    },
    "propertyPreferences": {
        "propertyType": undefined,
        "locationPreferences": undefined,
        "amenities": undefined,
        "otherPreferences": undefined
    },
    "preferences": {
        "likeMindedPeople": undefined,
        "incomeRangePreference": {
            "min": undefined,
            "max": undefined
        }
    },
    "membership": {
        "membershipId": undefined,
        "membershipType": undefined,
        "startDate": undefined,
        "endDate": undefined,
        "price": undefined
    },
    "createdOn": undefined,
    "lastLogin":undefined
}

const buyersSlice = createSlice({
    name: "buyer",
    initialState,
    reducers: {
        setBuyer: (state, action) => {
            // console.log(state);
            return state = action.payload;
        },
        updateBuyer : (state, action) => {
            if(action !== undefined){
                state.name = action.payload.name
            state.email = action.payload.email
            }
            // console.log(action);
        }
    },
});

export const { setBuyer, updateBuyer} = buyersSlice.actions;
export default buyersSlice.reducer;
