import React from 'react';
import  TextField  from '@mui/material/TextField';
import { useState } from 'react';
import  Box  from '@mui/material/Box';
import Button  from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import axios from 'axios';

import Typography  from "@mui/material/Typography";
  import { Done } from "@mui/icons-material";
const BugReport = () => {
    const [bugId, setBugId] = useState("");
    const [bugTitle, setBugTitle] = useState("");
    const [description, setDescription] = useState("");
    const [completeFormData, setCompleteFormData] = useState();
    const [ifFormSubmitted, setIfFormSubmitted] = useState(false);
    const {user} = useAuth0()
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://squad10-backend.onrender.com/bug',completeFormData).then(
            data => {
                console.log(data);
                setIfFormSubmitted(true)
            }
        )
    }
    useEffect(()=>{
        setBugId(user.email + Math.random(2000))
    },[])
    useEffect(()=>{
        setCompleteFormData({
            bugId : bugId,
            bugTitle : bugTitle,
            description : description
        })
    },[bugId,bugTitle,description])
    return (
        <div>
            <Box mt={5}>
            <Box mt={5}>
            <form style={{ width: "100%", textAlign: "center" }}>
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Bug Type"
              variant="outlined"
              value={bugTitle}
              onChange={(event) => setBugTitle(event.target.value)}
            />
            <br />
            <TextField
              style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
              type="text"
              label="Description"
              variant="outlined"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
            <br />
            <Box p={1}>
            {ifFormSubmitted ?
            <>
            <Typography variant="p">
              Bug Reported Successfully
            </Typography>
            <Done style={{ padding: 2 }}> </Done>
          </> 
             : <Button
             type="submit"
             variant="contained"
             color="primary"
             onClick={handleSubmit}
           >
             Submit
           </Button> }
            </Box>
          </form>
            </Box>
            </Box>
        </div>
    );
};

export default BugReport;