import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Avatar,
  TextField,
  Fab,
  Button,
  Typography,
  Paper,
  Grid,
  Container,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Done, Edit, Diamond, Home, DateRange, Pending,
  CurrencyRupee, Discount, AdfScanner, Person,
  Email, Phone, Cake, Work, Wc, Group, LocationOn,
  AttachMoney, Description,
  Psychology,
  Savings,
  Money
} from "@mui/icons-material";
import axios from "axios";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Payments from "../payments";
import { useSelector } from "react-redux";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  name: yup.string("Enter your name").required("Name is required"),
  phone: yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('A phone number is required'),
  dob: yup.date().typeError('The value must be a date (YYYY-MM-DD)').required('This field is required'),
  profession: yup.string("Enter your profession").required("Profession is required"),
  marital_status: yup.string("Enter your marital status").required("Marital Status is required"),
  gender: yup.string("Enter your gender").required("Gender is required"),
});

const Profile = () => {
  const { user } = useAuth0();
  const [editUser, setEditUser] = useState(false);
  const [userData, setUserData] = useState({});
  const [ifFormSubmitted, setIfFormSubmitted] = useState(false);
  const [propertyBookings, setPropertyBookings] = useState([]);
  const [membership, setMemberships] = useState();
  const selector = useSelector((state)=> state)


  useEffect(() => {
    setUserData(selector?.buyer);
    setMemberships(selector.buyer?.membership)
    // console.log(selector);
  }, [selector]);
  

  const handleUpdate = async (values) => {
    try {
      await axios.put("https://squad10-backend.onrender.com/users", values);
      setIfFormSubmitted(true);
      // fetchUserData();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: userData?.email || "",
      name: userData?.name || "",
      phone: userData?.mobile || "",
      dob: userData?.dob || "",
      profession: userData?.profession || "",
      marital_status: userData?.maritalStatus || "",
      gender: userData?.gender || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleUpdate,
    enableReinitialize: true,
  });

  return (
    <Container maxWidth="md">
      <Box mt={5} sx={{ display: "flex", justifyContent: "center", minHeight: "100%" }}>
        <Grid container spacing={3}>
          {!editUser && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Paper sx={{ padding: 3, display: "flex", justifyContent: "center" }}>
                  <Box position="relative">
                    <Avatar
                      variant="square"
                      sx={{ width: 100, height: 100, borderRadius: "10px", zIndex: "100" }}
                      alt=""
                      src={user.picture}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper sx={{ padding: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    User Information
                    <Button
                      onClick={() => setEditUser(!editUser)}
                    >
                      <Edit />
                    </Button>
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Person />
                      </ListItemIcon>
                      <ListItemText primary={`Name: ${userData?.name}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Email />
                      </ListItemIcon>
                      <ListItemText primary={`Email: ${userData?.email}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Phone />
                      </ListItemIcon>
                      <ListItemText primary={`Mobile: ${userData?.mobile}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Cake />
                      </ListItemIcon>
                      <ListItemText primary={`Date of Birth: ${userData?.dob}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Wc />
                      </ListItemIcon>
                      <ListItemText primary={`Gender: ${userData?.gender}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Group />
                      </ListItemIcon>
                      <ListItemText primary={`Marital Status: ${userData?.maritalStatus}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Work />
                      </ListItemIcon>
                      <ListItemText primary={`Profession: ${userData?.profession}`} />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
  <Paper sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Preferred Property and Budget
    </Typography>
    <List>
      {userData?.propertyPreferences && (
        <Box mb={2}>
          <ListItem>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary={`Preferred Property Type: ${userData?.propertyPreferences?.propertyType}`} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>
            <ListItemText primary={`Preferred Locations: ${userData?.propertyPreferences?.locationPreferences?.join(', ')}`} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Psychology />
            </ListItemIcon>
            <ListItemText primary={`Amenities: ${userData?.propertyPreferences?.amenities?.join(', ')}`} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Savings />
            </ListItemIcon>
            <ListItemText primary={`Budget Min: ${userData?.budget.min}`} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Money />
            </ListItemIcon>
            <ListItemText primary={`Budget Max: ${userData?.budget.max}`} />
          </ListItem>
        </Box>
      )}
    </List>
  </Paper>
</Grid>

              <Grid item xs={12} md={12}>
                <Paper sx={{ padding: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Property Bookings
                  </Typography>
                  <List>
                    {propertyBookings.map((booking) => (
                      <Box key={booking.id} mb={2}>
                        <ListItem>
                          <ListItemIcon>
                            <Home />
                          </ListItemIcon>
                          <ListItemText primary={`Property: ${booking.propertyName}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <DateRange />
                          </ListItemIcon>
                          <ListItemText primary={`Booking Date: ${booking.bookingDate}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Pending />
                          </ListItemIcon>
                          <ListItemText primary={`Status: ${booking.status}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <CurrencyRupee />
                          </ListItemIcon>
                          <ListItemText primary={`Price: ${booking.price}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Discount />
                          </ListItemIcon>
                          <ListItemText primary={`Discount: ${booking.discount}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AdfScanner />
                          </ListItemIcon>
                          <ListItemText primary={`Location: ${booking.location}`} />
                        </ListItem>
                      </Box>
                    ))}
                  </List>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12}>
                <Paper sx={{ padding: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    <Diamond /> Subscriptions
                  </Typography>
                  <List>
                  <Box key={membership?.memberId} mb={2}>
                        <ListItem>
                          <ListItemText primary={`Subscription Name: ${membership?.membershipType}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`Start Date: ${membership?.startDate}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`End Date: ${membership?.endDate}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`Amount Paid: ${membership?.price}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`Status: ${membership?.membershipStatus}`} />
                        </ListItem>
                      </Box>
                  </List>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12}>
                <Paper sx={{ padding: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Payments
                  </Typography>
                  <Payments />
                </Paper>
              </Grid>
            </Grid>
          )}
          {editUser && (
            <Grid item xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Paper sx={{ padding: 3 }}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    margin="normal"
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      id="dob"
                      name="dob"
                      label="Date of Birth"
                      value={formik.values.dob}
                      onChange={(value) => formik.setFieldValue("dob", value, true)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.touched.dob && Boolean(formik.errors.dob)}
                          helperText={formik.touched.dob && formik.errors.dob}
                          margin="normal"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    fullWidth
                    id="profession"
                    name="profession"
                    label="Profession"
                    value={formik.values.profession}
                    onChange={formik.handleChange}
                    error={formik.touched.profession && Boolean(formik.errors.profession)}
                    helperText={formik.touched.profession && formik.errors.profession}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    id="marital_status"
                    name="marital_status"
                    label="Marital Status"
                    value={formik.values.marital_status}
                    onChange={formik.handleChange}
                    error={formik.touched.marital_status && Boolean(formik.errors.marital_status)}
                    helperText={formik.touched.marital_status && formik.errors.marital_status}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    id="gender"
                    name="gender"
                    label="Gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                    helperText={formik.touched.gender && formik.errors.gender}
                    margin="normal"
                  />
                  <Box mt={2}>
                    <Fab
                      variant="extended"
                      color="primary"
                      type="submit"
                      sx={{ mr: 2 }}
                    >
                      <Done sx={{ mr: 1 }} />
                      Save
                    </Fab>
                    <Fab
                      variant="extended"
                      color="secondary"
                      onClick={() => setEditUser(false)}
                    >
                      <Edit sx={{ mr: 1 }} />
                      Cancel
                    </Fab>
                  </Box>
                </Paper>
              </form>
            </Grid>
          )}
        </Grid>
      </Box>

    </Container>
  );
};

export default Profile;
