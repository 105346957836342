import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { AddShoppingCart, Check, Tour, Videocam } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { useSelector } from 'react-redux';

export default function SquadTimeline() {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const squadTimeline = useSelector((state)=> state.squad.squad.activityTimeline)

  const squadTimelineData = [
    { activityId: "1", userId:"sada",projectId:"", time: "1:00 pm", action: "Deal Closed", icon_id: "check", short_desc: "Transaction successfully closed" }
  ];

  const iconList = {
    tour: <Tour />,
    video: <Videocam />,
    cart: <AddShoppingCart />,
    check: <Check />,
  };

  const handleTimelineClick = (item) => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const handleDialogueClose = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
      <Timeline position="alternate">
        {squadTimeline.map((item, index) => (
          <TimelineItem key={index} onClick={() => handleTimelineClick(item)}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {item?.time}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                {iconList[item?.icon_id]}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                {item?.action}
              </Typography>
              <Typography>{item?.short_desc}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Dialog open={isDialogOpen} onClose={handleDialogueClose}>
        <DialogTitle>{selectedItem?.action}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedItem?.short_desc}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
