import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Payments = () => {

  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [paymentData, setPaymentData] = React.useState([]);
  const {user} = useAuth0()
  
  const handleChangeNew = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDispute = (transectionID) => {
    axios.put('https://squad10-backend.onrender.com/payments/',{transectionID: transectionID})
  }
  useEffect(() => {
     axios.get('https://squad10-backend.onrender.com/payments/',{params:{transectionID:"all",user_account_id:user.email}}).then(
      data => {setPaymentData(data.data)}
    ).catch(
      err => {console.log(err);}
    )
  },[]
  )
  return (
    <div>
      <Box>
        {paymentData.length === 0 ? <Typography p={5} variant="p"> No Payments Recorded </Typography> :
        paymentData.map((card) => (
          <Accordion
            key={card.transectionID}
            expanded={expanded === card.transectionID}
            onChange={handleChange(card.transectionID)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography  sx={{ width: "33%", flexShrink: 0 }}>
              ₹ {card.amount}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {card.status}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card sx={{  padding:"10px",textAlign:"center" }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {card.date} {card.time}
                  </Typography>
                  <Typography variant="h5" component="div"></Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Transection Id - {card.transectionID}
                  </Typography>
                  <Typography variant="body2">{card.description}</Typography>
                </CardContent>
                <CardActions sx={{display:"flex", justifyContent:"center"}}> 
                <Button size="small" onClick={() => handleDispute(card.transectionID)}>Raise dispute</Button>
                </CardActions>
              </Card>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </div>
  );
};

export default Payments;
