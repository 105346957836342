import React from "react";

import Box from "@mui/material/Box";
import { Outlet, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import SquadTimeline from "../pages/SquadTimeline";
import SquadMembers from "../pages/Squad_Members";
import { useGetBuyerByIdQuery, useGetJobsQuery } from "../../Store/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { setBuyer } from "../../Store/slices/buyersSlice";
import JoinSquad from "../joinSquad/JoinSquad";
const Community = () => {
  const navigate = useNavigate();
  const [tabValue, settabValue] = useState("feed");
  const [featuredTechiesData, setfeaturedTechiesData] = useState([]);
  const selector = useSelector((state)=>state.buyer)
  const dispatch = useDispatch()
  const {user} = useAuth0()
  const handleChange = (event, newValue) => {
    settabValue(newValue);
    console.log(selector);
  };

  return (
    <div>
      <Box pt={1}  sx={{ width: "100%", textAlign: "center" }}>
        {selector.squadId === null ?
          <JoinSquad /> : 
          <Box display="flex" justifyContent="center">
          <Tabs onChange={handleChange} value={tabValue} selectionFollowsFocus>
            <Tab value="feed" label="Activity" />
            <Tab value="squad_members" label="Squad Members" />
          </Tabs>
          {tabValue === "feed" ? <SquadTimeline /> : <SquadMembers />}
        </Box>
        }
      </Box>
    </div>
  );
};

export default Community;
