import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import propertiesSlice from "./slices/propertiesSlice";
import squadsSlice from "./slices/squadsSlice";
import buyersSlice from "./slices/buyersSlice";
import authSlice from "./slices/authSlice";
import squadDealSlice from "./slices/dealSlice"
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        properties: propertiesSlice,
        squad: squadsSlice,
        buyer: buyersSlice,
        auth: authSlice,
        squadDeals :squadDealSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
