// Store/slices/squadsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const squadsSlice = createSlice({
  name: 'squads',
  initialState: {
    squad: {
      squadId: "",     
      squadName: "",
      members: [],
      agent: [],
      projectsShortlisted: [],
      activityTimeline: [],
      createdOn: ""
    },
    // other initial state fields
  },
  reducers: {
    setSquad: (state, action) => {
      state.squad = action.payload;
    },
    // other reducers
  },
});

export const { setSquad } = squadsSlice.actions;
export default squadsSlice.reducer;
